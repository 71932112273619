* {
    font-family: Inter, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

button {
    background-color: inherit;
}

button:hover {
    cursor: pointer;
    background-color: inherit;
}

@font-face {
  font-family: 'SansaStd';
  src: local('SansaStd'), url(./fonts/SansaPro-Light.otf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SansaStd';
  src: local('SansaStd'), url(./fonts/SansaPro-Normal.otf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SansaStd';
  src: local('SansaStd'), url(./fonts/SansaPro-SemiBold.otf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SansaStd';
  src: local('SansaStd'), url(./fonts/SansaPro-Bold.otf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-VariableFont_slnt,wght.ttf) format('truetype');
}